import React, { Fragment } from "react";
import "./news.scss";
import * as microsoftTeams from "@microsoft/teams-js";
import { News_Title } from "../../strings ";
import { GetTeamsConfig, GetNewsData, GetSharePointConfig, SharepointConfig, SharepointNewsItem, GetThumbnails, ThumbnailRequest } from "../../services/serviceApi";
import moment from "moment";
import {Carousel} from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { findByTestId } from "@testing-library/react";
import { SharepointContext } from "../../App";
import {HandThumbsUp, ChatDots} from "react-bootstrap-icons"
type IProps = RouteComponentProps & {
  newsData: Array<any>;
}

interface IState {
  newsData?: NewsContent[];
  DeepLinkBaseUrl?: string | null;
  payStubs?: string | null;
  benefits?: string | null;
  rewards?: string | null;
  kudos?: string | null;
  survey?: string | null;
  report?: string | null;
  shifts?: string | null;
  channelChatUrl?: string | null;
  portalAppId?: string | null;
  surveyAppId?: string | null;
  insightsAppId?: string | null;
  newsAppId?: string | null;
  sharepointConfig: SharepointConfig | null;
}

class News extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      newsData: [],
      DeepLinkBaseUrl: null,
      payStubs: null,
      benefits: null,
      rewards: null,
      kudos: null,
      survey: null,
      report: null,
      shifts: null,
      channelChatUrl: null,
      portalAppId: null,
      surveyAppId: null,
      insightsAppId: null,
	  newsAppId: null,
	  sharepointConfig: null
    };

    this.openTask.bind(this.openTask);
  }

  openTask = (pageId: string, bannerUrl: string, siteId?:string) => {
    this.props.history?.push(`/news?pageId=${pageId}&bannerUrl=${encodeURIComponent(bannerUrl)}&siteId=${siteId}`)
  };

  overViewTask = () => {
    this.props.history?.push(`/news/overview`)
  }

  async componentDidMount() {
	this.bindData();
	//$('carousel').carousel({interval:4000})
  }

  async bindData() {

    //getting team config details
    let teamsConfigfromApi = await GetTeamsConfig().catch((err: any) => {
      console.log(err);
    });
    let sharePointConfigfromApi: SharepointConfig = await GetSharePointConfig();
	
	let newsData = await GetNewsData(sharePointConfigfromApi.listGuuid, sharePointConfigfromApi.siteGuuid);
	const newsContent = newsData.value.map<NewsContent>(value => mapFieldsToNewsContent(value));
	if (newsData !== null) {
			GetThumbnails(newsData.value.map<ThumbnailRequest>((data) => {
				return {
					id: data.id.toString(),
					url: data.fields.BannerImageUrl.Url + "&resolution=4"
				}
			})).then(nails => {
				newsContent.forEach(content => content.bannerContent = nails.find(nail => nail.id === content.id)?.b64Content)
				this.setState({
					newsData: newsContent,
				});
			}).catch(error => console.log(error));


	}

    if (teamsConfigfromApi != null && sharePointConfigfromApi != null) {
      this.setState({
		sharepointConfig: sharePointConfigfromApi,
        DeepLinkBaseUrl: teamsConfigfromApi?.deepLinkBaseUrl,
        payStubs: teamsConfigfromApi?.payStubsAppId,
        benefits: teamsConfigfromApi?.benefitsAppId,
        rewards: teamsConfigfromApi?.rewardsAppId,
        kudos: teamsConfigfromApi?.kudosAppId,
        survey: teamsConfigfromApi?.surveyAppId,
        report: teamsConfigfromApi?.reportAppId,
        shifts: teamsConfigfromApi?.shiftsAppId,
        channelChatUrl: teamsConfigfromApi?.chanpnelChatUrl,
        portalAppId: teamsConfigfromApi?.portalAppId,
        surveyAppId: teamsConfigfromApi?.surveyAppId,
        insightsAppId: teamsConfigfromApi?.insightsAppId,
        newsData: newsContent,
        newsAppId: teamsConfigfromApi?.data?.newsAppId,
      });
    } else {
      console.log("No Team Config Found for News!!");
    }
  }



  render() {
	const { newsData = [], sharepointConfig } = this.state;
    return (
      <Fragment>
        <div className="news-head">
			<div className="row-title">
				{News_Title}
			</div>
			<div
				className="see-more3"
				id="news"
				onClick={() => this.overViewTask()}
			>
				See more
			</div>
		</div>
        
        {newsData.length > 1 ? (
          <div className="carousel3">
			<Carousel interval={5000} indicators={true} controls={false}>

			{newsData.map(content => 
				<Carousel.Item key={content.id}
				// className={`carousel-item${active ? " active" : ""}`}
				// onClick={clickFunc}
				// id={`newsLink${contents.id}`}
				>
					{content.bannerContent ? 
						<img className="d-block w-100" src={`data:image/jpeg;base64,${content.bannerContent}`} alt="Loading..."/>
					:
						""}
					<Carousel.Caption onClick={() => this.openTask(content.etag, content.bannerUrl, sharepointConfig?.siteGuuid)}>
						<h3>{content.title}{" "}</h3>
						<p>{content.description}</p>
						<div className="interaction-wrapper"> 
							<div className="likes interaction-element">
								<p>{content.likes}</p>
								<HandThumbsUp/>
							</div>
							<div className="comments interaction-element">
								<p>{content.comments}</p>
								<ChatDots/>
							</div>
						</div>
						{/* <p >{content.date?.format("DD.MM.YYYY")}</p> */}
					</Carousel.Caption>
				</Carousel.Item>
			)}
			</Carousel>

          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const NewsContainer: React.FC<{contents: NewsContent[], clickFunc:() => void}> = ({contents, clickFunc}) => {
	return (
		<>
			{contents.map(content => <NewsComponent contents={content} clickFunc={clickFunc} key={content.id}/>)}
		</>
	)
}

const NewsComponent: React.FC<{contents: NewsContent, clickFunc:() => void}> = ({contents, clickFunc})  => {
	console.log(contents)
	return (
		<Carousel.Item
			// className={`carousel-item${active ? " active" : ""}`}
			// onClick={clickFunc}
			// id={`newsLink${contents.id}`}
		>
			{contents.bannerContent ? 
				<img className="d-block w-100" src={`data:image/jpeg;base64,${contents.bannerContent}`} alt="Loading..."/>
			:
				""}
			<Carousel.Caption>
				<h1>{contents.title}{" "}</h1>
				<p>{contents.description}</p>
				<p >{contents.date?.format("DD.MM.YYYY")}</p>
			</Carousel.Caption>
		</Carousel.Item>
	);
}

const mapFieldsToNewsContent = (field: SharepointNewsItem, content?: NewsContent): NewsContent => {
	return {
		id: field.id?.toString(),
		bannerUrl: field.fields.BannerImageUrl.Url,
		description: field.fields.Description,
		url: field.fields.EncodedAbsUrl,
		bannerContent: content?.bannerContent,
		title: field.fields.Title,
    	date: moment(field.fields.ValoNewsPublishDate),
		etag: field.eTag?.split(",")[0]?.replace("\"", ""),
		likes: field.fields._LikeCount === "" ? 0 : parseInt(field.fields._LikeCount),
		comments: field.fields._CommentCount === "" ? 0 : parseInt(field.fields._CommentCount)
	}
}


interface NewsContent {
	id : string;
  url: string;
  etag: string;
	bannerUrl: string;
	description: string;
	bannerContent?: string;
	title: string;
	date: moment.Moment;
	likes: number;
	comments: number;
}

//const componentClassWithHistory = (withRouter(News as any) as any);

export default  withRouter(News);
