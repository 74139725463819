import React, { Fragment } from "react";
import "../AppItem/appItem.scss";
import * as microsoftTeams from "@microsoft/teams-js";
import { GetTeamsConfig } from "../../services/serviceApi";
import {App_Title} from "../../strings ";
import {Container, Row, Col, Card} from "react-bootstrap";

interface IProps {
  lobApplications?: Array<any>;
}

interface IState {
  DeepLinkBaseUrl?: string | null;
  payStubs?: string | null;
  benefits?: string | null;
  rewards?: string | null;
  kudos?: string | null;
  survey?: string | null;
  report?: string | null;
  shifts?: string | null;
  channelChatUrl?: string | null;
  portalAppId?: string | null;
  surveyAppId?: string | null;
  insightsAppId?: string | null;
  trainingAppId?: string | null;
}

class AppItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      DeepLinkBaseUrl: null,
      payStubs: null,
      benefits: null,
      rewards: null,
      kudos: null,
      survey: null,
      report: null,
      shifts: null,
      channelChatUrl: null,
      portalAppId: null,
      surveyAppId: null,
      insightsAppId: null,
      trainingAppId: null,
    };

    this.openTask.bind(this.openTask);
  }

  openTask = (DeepLinkBaseUrl: any, appName: string) => {
    microsoftTeams.initialize();
    let urlToOpen = "";

    switch (appName) {
      case "Training":
        urlToOpen =
		  "https://teams.microsoft.com/l/entity/7cab7a5d-e732-4d47-8b27-753a4a269703/_djb2_msteams_prefix_2785484894?context=%7B%22subEntityId%22%3Anull%2C%22channelId%22%3A%2219%3A74248b294ae44d5f966c77c9b4beeca3%40thread.tacv2%22%7D&groupId=2fcf3230-9f32-489f-a0c7-4c24b6af82cf&tenantId=ae3c4068-0e02-4063-bae8-3f0ab63d6ac6";
		  
        break;
      case "Insights":
        urlToOpen =
          DeepLinkBaseUrl +
          this.state.insightsAppId +
          "/5da00cfb-a533-41fc-bfd4-b4e31e7a2695";
        break;
      case "Survey":
        urlToOpen = DeepLinkBaseUrl + this.state.surveyAppId + "/surveys";
        break;
      case "Portal":
        urlToOpen = DeepLinkBaseUrl + this.state.portalAppId + "/portal";
        break;
      case "Rewards":
        urlToOpen = DeepLinkBaseUrl + this.state.rewards + "/rewards";
        break;
      case "Kudos":
        urlToOpen = DeepLinkBaseUrl + this.state.kudos + "/kudos";
        break;
      case "Benefits":
        urlToOpen = DeepLinkBaseUrl + this.state.benefits + "/benefits";
        break;
      case "PayStubs":
        urlToOpen = DeepLinkBaseUrl + this.state.payStubs + "/payslipsviewer";
        break;
      default:
        urlToOpen = DeepLinkBaseUrl + this.state.portalAppId + "/portal";
        break;
	}
	console.log(urlToOpen)
    microsoftTeams.executeDeepLink(urlToOpen);
  };

  async componentDidMount() {
    this.bindData();
  }

  async bindData() {
    //getting team config details
    let teamsConfigfromApi = await GetTeamsConfig().catch((err: any) => {
      console.log(err);
    });
    if (teamsConfigfromApi !== null && teamsConfigfromApi?.length !== 0) {
      this.setState({
        DeepLinkBaseUrl: teamsConfigfromApi?.deepLinkBaseUrl,
        payStubs: teamsConfigfromApi?.payStubsAppId,
        benefits: teamsConfigfromApi?.benefitsAppId,
        rewards: teamsConfigfromApi?.rewardsAppId,
        kudos: teamsConfigfromApi?.kudosAppId,
        survey: teamsConfigfromApi?.surveyAppId,
        report: teamsConfigfromApi?.reportAppId,
        shifts: teamsConfigfromApi?.shiftsAppId,
        channelChatUrl: teamsConfigfromApi?.chanpnelChatUrl,
        portalAppId: teamsConfigfromApi?.portalAppId,
        surveyAppId: teamsConfigfromApi?.surveyAppId,
        insightsAppId: teamsConfigfromApi?.insightsAppId,
        trainingAppId: teamsConfigfromApi?.trainingAppId,
      });
    } else {
      console.log("No Team Config Found for AppItem!!");
    }
  }

  render() {
    return (
      <Fragment>

        <div className="row-title">{App_Title}</div>
		<div className="apps row">
			<Container>
				<Row xs={2} md={4}>
					{this.props.lobApplications?.map((app, i) => {
					return (
						<Col className="lob-cards" key={i}>
							<Card >
								<Card.Img src={app.lobApp.lobAppLogoUrl} alt={app.lobApp.lobAppName} onClick={() =>
									this.openTask(
										this.state.DeepLinkBaseUrl != null
										? this.state.DeepLinkBaseUrl
										: null,
										app.lobApp.lobAppName
									)}
								/>
								<Card.Body>
									<Card.Title as="h4">{app.lobApp.lobAppName}</Card.Title>
									<Card.Text as="p">{app.lobApp.lobAppDescription}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					)})}
				</Row>
			</Container>
			</div>
      </Fragment>
    );
  }
}

export default AppItem;
