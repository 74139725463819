import React, { useEffect } from "react";
import Home from "./components/Home/Home";
import Learning from "./components/Learning/Learning";
import SampleData from "./assets/data/data.json";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import OffShift from "./components/Shift/OffShift";
import {NewsPage} from "./components/NewsPage/newsPage";
import { GetSharePointConfig, SharepointConfig, GetValoCategories, ValoCategoryGroup } from "./services/serviceApi";
import { NewsOverview } from "./components/NewsOverview/newsOverview";
import './assets/styles/bootstrap.min.css';
import { StateLoader, StateSetter } from "./types";
import { locale } from "./strings ";
import { tmpdir } from "os";

export const SharepointContext = React.createContext<SharepointConfig|undefined>(undefined)


const App: React.FC = () => {
  const [valoCategories, setValoCategories] = React.useState<ValoCategoryGroup[]>([]);
  const [sharepointConfig, setSharepointConfig] = React.useState<SharepointConfig|undefined>(undefined);

  //Load Sharepoint Config
  useEffect(() => {
	genericLoad(setSharepointConfig, GetSharePointConfig)
  }, [setSharepointConfig])

  //Load Valo Categories
  useEffect(() => {
	  if (sharepointConfig) {
		  genericLoad(setValoCategories, GetValoCategories, sharepointConfig.valoCategoryTaxonomyGroupId, sharepointConfig.valoCategoryTaxonomySetIds, locale)
	  }
  }, [sharepointConfig, setValoCategories])

  return (
    <SharepointContext.Provider value={sharepointConfig}>
      <Router>
        <div className="App" id="wrapper">
          <Switch>
            <Route exact path="/">
              <Home
                data={SampleData}
                configuredData={SampleData.configuredData}
              />
            </Route>
            <Route exact path="/home">
              <Home
                data={SampleData}
                configuredData={SampleData.configuredData}
              />
            </Route>
            <Route exact path="/learning">
              <Learning data={SampleData} />
            </Route>
            <Route exact path="/offshift">
              <OffShift data={SampleData} />
            </Route>
			<Route exact path="/news/overview" >
              <NewsOverview categories={valoCategories}/>
            </Route>
            <Route exact path="/news" >
              <NewsPage/>
            </Route>
          </Switch>
        </div>
      </Router>
    </SharepointContext.Provider>

  );
};

const transformedLoad = async <T, R, P extends unknown[]>(
	setter: StateSetter<R>|StateSetter<R|undefined>, 
	loader: (...args: P) => Promise<T>,
	tranformer: (loaded: T) => R,
	...args: P): Promise<void> => {

		const val = await loader(...args);
		const transformed = tranformer(val);
		setter(transformed)

}

const genericLoad = async <T, P extends unknown[]>(setter: StateSetter<T>|StateSetter<T|undefined>, loader: (...loadArgs: P) => Promise<T>, ...args: P): Promise<void> => {
	const val = await loader(...args);
	console.log("GenericLoad is setting:")
	console.log(val)

	setter(val);
}

export default App;
