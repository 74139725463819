import axios, { AxiosInstance, AxiosResponse } from "axios";
import { apiendpoint } from "../links";
import ApiClientOptions from "./ApiClientOptions"
/**
 * Configuration class needed in base class.
 * The config is provided to the API client at initialization time.
 * API clients inherit from #AuthorizedApiBase and provide the config.
 */


export default class ApiClientBase {
	protected instance: AxiosInstance;
	private readonly config: ApiClientOptions;

	protected constructor(config: ApiClientOptions) {
		this.config = config || new ApiClientOptions();
		this.instance = axios.create();

		this.instance.interceptors.request.use((axiosConfig) => {
			// Check and acquire a token before the request is sent
			return new Promise(async (resolve, reject) => {
				let token = await this.config.getAuthorization(); //authContext.getCachedToken(adalConfig.clientId);
				//console.log("Retrieved cached token by ADAL: " + token);

				if (!!token) {
					axiosConfig.headers.Authorization = `Bearer ${token}`;
					axiosConfig.headers.Accept = "application/json";
					axiosConfig.transformResponse = (resp => resp);
					resolve(axiosConfig)
				}

				reject(axiosConfig);
			})
		}, function (error) {
			// Do something with error of the request
			return Promise.reject(error)
		});
	}

	/**
	 * This has to be used when using BaseClient
	 */
	protected transformResult(url: string, response: AxiosResponse, processor: (response: AxiosResponse) => any): any {
		console.log('transformResult', response);
		return processor(response.data);
	}

	protected getBaseUrl = (defaultUri?: string): string => {
		return apiendpoint ?? defaultUri;
	}
}