import React, { Fragment } from "react";
import Task from "../Task/Task";
import Shift from "../Shift/Shift";
import Announcements from "../Announcements/Announcements";
import WorkingNow from "../WorkingNow/WorkingNow";
import TeamMember from "../TeamMember/TeamMember";
import News from "../News/News";
import AppItem from "../AppItem/AppItem";
import Footer from "../Footer/Footer";
import NewBannerLogo from "../NewBannerLogo/NewBannerLogo";
import { GraphHomeapi } from "../../services/serviceApi";
import "../Home/home.css";
import{Container, Row, Col} from "react-bootstrap"

interface IProps {
  data: any;
  configuredData: any;
}
interface IState {
  userData: any;
}

class Home extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      userData: {},
    };
  }

  async componentDidMount() {
    this.bindData();
  }

  async bindData() {
    //getting announcemet adaptive card details
    let graphHomeapi = await GraphHomeapi().catch((err: any) => {
      console.log(err);
    });
    if (graphHomeapi != null) {
      this.setState({
        userData: graphHomeapi,
      });
    }
  }
  render() {
    const { data, configuredData } = this.props;
    const { userData } = this.state;
    let myDate = new Date();
    let hrs = myDate.getHours();
    let greet;
    if (hrs < 12) {
      greet = "Guten Morgen, ";
    } else if (hrs >= 12 && hrs <= 17) {
      greet = "Guten Tag, ";
    } else if (hrs >= 17 && hrs <= 24) {
      greet = "Guten Abend, ";
    }

    return (
      <Container id={"content"}>
          <Row className="banner-logo">
			  <Col>
				<NewBannerLogo
					title={configuredData.appTitle}
					logo={configuredData.logoImage}
				/>
			  </Col>
          </Row>
		  <Row className="main-heading">
			<Col as="span" className="greet">
              {`${greet}${userData.givenName}`}!
			</Col>
		  </Row>
		  <Row className="news">
			<Col>
				<News newsData={data.newsData} />
			</Col>
		  </Row>
			<Row className="announcenment">
				<Col>
					<Announcements announcementData={data.announcementData} />
				</Col>
			</Row>
			<Row className="apps">
				<Col>
					<AppItem
						lobApplications={configuredData.lobApplicationsLookUp?.filter(
							(app: any) => app.lobApp.tab === "home"
						)}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="tasks">
					<Task />
				</Col>
			</Row>
			{userData?.id && configuredData.teamId ? (
				<Row>
					<Col>
						<Shift
						shiftData={data.sharedShiftData}
						userId={userData.id}
						teamId={configuredData.teamId}
						/>
					</Col>
				</Row>
			) : (
				""
			)}
			<Row className="working">
				<Col>
					<WorkingNow WorkingNow={data.workingNowData} />
				</Col>
			</Row>
			<Row className="new-team">
				<Col>
					<TeamMember TeamMember={data.workingNowData} />
				</Col>
			</Row>
			{/* <Row >
				<Col>
					<Footer
						text={configuredData.homeFooterText}
						imageSrc={configuredData.footerImage}
					/>
				</Col>
			</Row> */}
      </Container>
    );
  }
}

export default Home;
