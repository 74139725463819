import React, { Fragment } from "react";
import moment from "moment";
import "./shift.css";
import { GetShiftDetails } from "../../services/serviceApi";
import { Card } from "react-bootstrap";

interface IProps {
  shiftData: any;
  userId: string;
  teamId: string;
}

interface IState {
  shiftTitle: string;
  displayName: string;
  startDateTime: string;
  endDateTime: string;
}

class Shift extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      shiftTitle: "",
      displayName: "",
      startDateTime: "",
      endDateTime: "",
    };
    this.getShift = this.getShift.bind(this);
  }

  async componentDidMount() {
    this.getShift(this.props.teamId);
  }

  // getting shift details
  async getShift(teamId: string) {
    let dateTimeNow = new Date().toISOString();
    let shiftFromDate = new Date();
    let objectId = this.props.userId;
    shiftFromDate.setDate(shiftFromDate.getDate() - 1);
    let graphShiftsUrl = `https://graph.microsoft.com/beta/teams/${teamId}/schedule/shifts?$filter=sharedShift/startDateTime ge ${(new Date()).toISOString()}`;
    let graphTemp: any[] = [];
	let shiftTitle = "";
	console.log("Getting ShiftDetails!");
    let shiftDetails = await GetShiftDetails(graphShiftsUrl).catch(
      (err: any) => {
        console.log(err);
      }
    );
	console.log("These are the shiftdetails:")
	console.log(shiftDetails);
    if (shiftDetails) {
      graphShiftsUrl = shiftDetails["@odata.nextLink"];
      graphTemp = graphTemp.concat(shiftDetails.value);
      graphTemp.sort(this.sortShifts);
      let shift = graphTemp.find(
        (s) =>
          s.userId === objectId &&
          ((s.sharedShift.startDateTime <= dateTimeNow &&
            s.sharedShift.endDateTime >= dateTimeNow) ||
            s.sharedShift.startDateTime >= dateTimeNow)
      );
      if (shift) {
        if (shift.sharedShift.startDateTime >= dateTimeNow) {
          shiftTitle = "Ihre nächste Schicht:";
        } else {
          shiftTitle = "Ihre aktuelle Schicht:";
        }
        this.setShiftCard(shift);
      } else {
        shiftTitle = "Keine Anstehenden Schichten";
      }
    } else {
      console.log("No Team Config Found for Shifts!!");
      console.log("Error!!!");
    }
    this.setState({ shiftTitle });
  }

  sortShifts = (a: any, b: any) => {
    if (
      new Date(a.sharedShift.startDateTime) <
      new Date(b.sharedShift.startDateTime)
    )
      return -1;
    if (
      new Date(a.sharedShift.startDateTime) >
      new Date(b.sharedShift.startDateTime)
    )
      return 1;
    return 0;
  };

  setShiftCard = (shift: any) => {
    this.setState({
      displayName: shift?.sharedShift?.displayName,
      startDateTime: shift.sharedShift?.startDateTime,
      endDateTime: shift.sharedShift.endDateTime,
    });

    const lineEle = document.getElementById("line");
    if (lineEle) {
      if (shift.sharedShift.theme.includes("dark")) {
        lineEle.style.background = shift.sharedShift.theme
          .substr(4)
          .toLowerCase();
      } else {
        lineEle.style.background = shift.sharedShift.theme
          .substr(4)
          .toLowerCase();
      }
    }
  };

  render() {
    const { shiftTitle, startDateTime, endDateTime, displayName } = this.state;
    return (
      <Fragment>
        <div className="sub-heading">
          <span id="tasksCount" className="tasksCount">
            {shiftTitle}
          </span>
        </div>
        {startDateTime && (
		  <Card className="pointer" id="shifts">
			  <Card.Body>
				<div>
					<div className="date" id="shiftDate">
						{moment(startDateTime).format("DD")}
					</div>
					<div className="day" id="shiftDay">
						{moment(startDateTime).format("ddd")}
					</div>
				</div>
				<div className="line" id="line"></div>
				<div>
					<div className="time" id="shiftHours">{`${moment(
						startDateTime
					).format("h:mm A")} - ${moment(endDateTime).format(
						"h:mm A"
					)}`}
					</div>
				</div>
				<div>
					<div className="msg" id="shiftName">
						{displayName}
					</div>
				</div>
			  </Card.Body>

		  </Card>
        //   <div className="card pointer" id="shifts">

        //   </div>
        )}
      </Fragment>
    );
  }
}

export default Shift;
