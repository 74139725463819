
export const apiendpointWithoutSlash="https://workerhub.otterdev.net"; //public
//export const apiendpointWithoutSlash="https://localhost:44354"; //local
export const apiendpoint=`${apiendpointWithoutSlash}/`; //local
export const authConfig = {
	tenant: "ae3c4068-0e02-4063-bae8-3f0ab63d6ac6",
	client: "01d90df4-d09c-4fd9-afb5-43ee52269bf3" //online
	//client: "f5e4eaf5-be13-4fdb-ae3f-0c9c082d9542" //offline
}
export const AnnouncementAdaptiveCardDetails =
  `${apiendpoint}samplehub/AnnouncementAdaptiveCardDetails`;
export const TeamsConfig =
  `${apiendpoint}samplehub/TeamsConfig`;
export const SharepointThumbnails =
  `${apiendpoint}samplehub/NewsThumbnails`;
export const PersonalNews =
  `${apiendpoint}samplehub/PersonalNews`;
export const SharePointConfig =
  `${apiendpoint}samplehub/SharepointConfig`;
export const NewsData =`${apiendpoint}samplehub/NewsData`;
export const TeamMemberDetails =`${apiendpoint}samplehub/TeamMemberDetails`;
export const TeamMember_Link1 =
"{Metnion team member link}";
export const TeamMember_Link2 =
"{Metnion second team member link}";
export const GetUserAccessToken =
`${apiendpoint}samplehub/GetUserAccessToken`;
export const Announcements_TeamsLink =
"{Mention compnay communicator app tempalte link}";
export const Announcements_TeamsLink_1 =
`${apiendpoint}samplehub/AnnouncementAdaptiveCardDetails`;
export const WorkingNow_Link = "https://teams.microsoft.com/l/chat/0/0?users=";
export const Task_Link1 = "https://graph.microsoft.com/v1.0/me/planner/tasks";
export const Task_Link2 =
  `https://teams.microsoft.com/l/entity/`;
export const Task_Link3 =
  `https://teams.microsoft.com/l/entity/`;
export const Learning_Link1 = 
"{Mention your learning app link}";
export const Learning_Link2 =
"{Mention your learning app link}";
export const Learning_Link3 =
"{Mention your learning app link}";
