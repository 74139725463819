import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getIdToken, getAccessToken } from "../services/authService";
import { TeamsConfig,Task_Link1, PersonalNews,TeamMemberDetails,AnnouncementAdaptiveCardDetails, SharePointConfig, SharepointThumbnails, apiendpointWithoutSlash} from "../links";
import { Moment } from "moment";
import { ApiClient } from "./ApiClient";
import ApiClientOptions from "./ApiClientOptions";

const apiClient = new ApiClient(new ApiClientOptions(),  apiendpointWithoutSlash);

export const GetAnnouncementAdaptiveCardDetails = async () => {
  
  let data: Array<any> = [];
  await axios
      .get(`${AnnouncementAdaptiveCardDetails}`, {
      headers: {
        "Content-Type": "application/text",
        Authorization: "Bearer " + getIdToken(),
      },
    })    
    .then((res) => {
      if (res.status === 200) {
        data = res.data.value;
      } else {
        data = [];
      }
    });
  return data;
};

export const GetSharePointConfig = async(): Promise<SharepointConfig> => {
	var configResponse = await axios.get<SharepointConfig>(SharePointConfig, {
		headers: {
			"Content-Type": "application/text",
			Authorization: "Bearer " + getIdToken(),
		}
	})

	if (configResponse.status === 200) {
		return configResponse.data;
	}

	throw new Error("SharepointConfig returned " + configResponse.statusText + ": " + configResponse.statusText);

}

export const GetTeamsConfig = async () => {
  let data: any;
  await await axios
  .get(`${TeamsConfig}`, {
  headers: {
    "Content-Type": "application/text",
    Authorization: "Bearer " + getIdToken(),
  },
}).then((res) => {
    if (res.status === 200) {
      data = res.data;
    }
  });
  return data;
};

export const GetPersonalNews = async ():  Promise<PersonalNewsResponse[]>=> {
	const webResponse = await axios.get<PersonalNewsResponse[]>(PersonalNews, {
		headers: {
			Authorization: "Bearer "+ getIdToken(),
			"Content-Type": "application/json"
		},
	});

	if (webResponse.status === 200) {
		return webResponse.data;
	}

	throw new Error("Thumbnails returned " + webResponse.statusText + ": " + webResponse.statusText);
	return[];
}

export const GetThumbnails = async (requests: ThumbnailRequest[]):  Promise<ThumbnailResponse[]>=> {
	const webResponse = await axios.post<ThumbnailResponse[]>(SharepointThumbnails, requests, {
		headers: {
			Authorization: "Bearer "+ getIdToken(),
			"Content-Type": "application/json"
		},
	});

	if (webResponse.status === 200) {
		return webResponse.data;
	}

	throw new Error("Thumbnails returned " + webResponse.statusText + ": " + webResponse.statusText);
}

export const GraphHomeapi = async () => {
  let userProfile: any;
  let config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  await axios
    .get("https://graph.microsoft.com/v1.0/me", config)
    .then((res: { data: any }) => {
      userProfile = res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return userProfile;
};

export const GetNewsContent = async (newsId : string, siteId: string): Promise<GraphNewsContentResponse> => {
	const response = await axios.get<GraphNewsContentResponse>(`https://graph.microsoft.com/beta/sites/{${siteId}}/pages?filter=id eq '${newsId}'&select=webParts,title`, {
		headers: {
			"Content-Type": "application/text",
			Authorization: "Bearer " + getAccessToken(),
			Prefer: "allowthrottleablequeries"
		}
	  });
	
	  if (response.status === 200) {
		return response.data;
	  }
	
	  throw new Error("Sharepoint Page for content returned " + response.statusText + ": " + response.statusText)
	
}

export const GetNewsData = async (listId: string, siteId: string): Promise<SharepointNewsListResponse> => {
  const response = await axios.get<SharepointNewsListResponse>(`https://graph.microsoft.com/beta/sites/${siteId}/lists/${listId}/items?select=id,etag&orderby=fields/ValoNewsPublishDate desc&expand=fields(select=Title,Description,ValoNewsPublishDate,ValoExpirationDate,ValoTags,ValoBusinessArea,ValoOfficeLocation,BannerImageUrl,_OriginalSourceUrl,_SourceUrl,EncodedAbsUrl,_CommentCount,_LikeCount)&filter=fields/ValoHighlight eq 1&top=3`, {
	headers: {
		"Content-Type": "application/text",
		Authorization: "Bearer " + getAccessToken(),
		Prefer: "allowthrottleablequeries"
	}
  })

  if (response.status === 200) {
	return response.data;
  }

  throw new Error("SharepointList returned " + response.statusText + ": " + response.statusText)

};

export const GetValoCategories = async (termGroupId: string, termSetIds: string[], locale: string): Promise<ValoCategoryGroup[]> => {
	const termendpoint = "https://graph.microsoft.com/beta/termstore/groups";
	const filters = termSetIds.map(id => `id eq '${id}'`);
	const combinedFilter = filters.reduce((acc, current) => `${acc} or ${current}`);

	const setResponse = axios.get<GraphValueResponse<SharepointTermSet>>(`${termendpoint}/${termGroupId}/sets?filter=${combinedFilter}&select=id,localizedNames`, {
		headers: {
			"Content-Type": "application/text",
			Authorization: "Bearer " + getAccessToken(),
			Prefer: "allowthrottleablequeries"
		}
	});
	
	console.log(`${termendpoint}/${termGroupId}/sets?filter =${combinedFilter}&select=id,localizedNames`)
	
	const termResponses = termSetIds.map(termSetId => {
		return {
			requestPromise: axios.get<GraphValueResponse<SharepointTerm>>(`${termendpoint}/${termGroupId}/sets/${termSetId}/terms?select=labels,id`, {
				headers: {
					"Content-Type": "application/text",
					Authorization: "Bearer " + getAccessToken(),
					Prefer: "allowthrottleablequeries"
				}
			}),
			id: termSetId
		}
	
	})

	console.log("Awaiting Promises...")
	const resps: Promise<AxiosResponse<unknown>>[] = [...(termResponses.map(resp => resp.requestPromise)), setResponse];
	const responses = await Promise.all(resps);

	console.log("Promises awaited")

	responses.forEach(resp => {
		if (resp.status === 200) {
			return resp.data;
		}

		throw new Error("Taxonomy endpoint returned " + resp.statusText + ": " + resp.statusText)
	})

	const sets = await setResponse;
	const categoryPromises = sets.data.value.map<Promise<ValoCategoryGroup | undefined>>(async (set) => {
		const termWrapper = termResponses.find(term => term.id === set.id);
		if (termWrapper) {
			const term = await termWrapper.requestPromise;
			let setName = set.localizedNames.find(locallizedName => locallizedName.languageTag === locale)?.name;
			if (!setName) {
				setName = set.localizedNames[0]?.name
			}
			const categoryGroup: ValoCategoryGroup = {
				categories: term.data.value.map<ValoCategory>(cat => {
					let categoryName = cat.labels.find(label => label.language === locale)?.name;
					if (!categoryName) {
						categoryName = cat.labels[0]?.name;
					}
					return {
						name: categoryName,
					}
				}),
				name: setName,
			};
			return categoryGroup;
		}
		return undefined;
	});

	//Filter null Values
	const categories: ValoCategoryGroup[] = (await Promise.all(categoryPromises)).filter(cat => cat).map<ValoCategoryGroup>(cat => cat as ValoCategoryGroup);
	console.log("Returning Categories...")
	return categories;
}

export const GetFilteredNews = async (valoTags: string[], locations: string[]) => {
	const newsResponse = await apiClient.getFilteredNews(valoTags, locations);
	return newsResponse;

}

let generalNewsNextLink:string|undefined  = undefined;

export const GetGeneralNewsData = async (listId: string, siteId: string, resultsCount:number, loadNextPage = false): Promise<SharepointNewsListResponse> => {
	let link = `https://graph.microsoft.com/beta/sites/${siteId}/lists/${listId}/items?select=id,etag&orderby=fields/ValoNewsPublishDate desc&expand=fields(select=Title,Description,ValoNewsPublishDate,ValoExpirationDate,ValoTags,ValoBusinessArea,ValoOfficeLocation,BannerImageUrl,_OriginalSourceUrl,_SourceUrl,EncodedAbsUrl)&top=${resultsCount}`;
	if (loadNextPage) {
		if (generalNewsNextLink) {
			link = generalNewsNextLink;
		}
	}

	else {
		generalNewsNextLink = undefined;
	}

	
	const response = await axios.get<SharepointNewsListResponse>(link, {
	  headers: {
		  "Content-Type": "application/text",
		  Authorization: `Bearer ${getAccessToken()}`,
		  Prefer: "allowthrottleablequeries"
	  }
	})
	if (response.status === 200) {
		generalNewsNextLink = response.data["@odata.nextLink"];
	  	return response.data;
	}
  
	throw new Error("SharepointList returned " + response.statusText + ": " + response.statusText)
  
  };

export const GetTeamMemberDetails = async () => {
  let data: any;
  
  await await axios
  .get(`${TeamMemberDetails}`, {
  headers: {
    "Content-Type": "application/text",
    Authorization: "Bearer " + getIdToken(),
  },
})
    .then((res: { data: any }) => {
        data = res.data
    });
  return data;
};
export const GetTaskDetails = async () => {
  let data: any;
  let config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  await axios.get(`${Task_Link1}`, config).then((res: {status:number, data: any }) => {
    if (res.status === 200) {
      data = res.data;
    }
  });
  return data;
};

export const GetShiftDetails = async (linkValue: string) => {
	let data: any;
  let config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  };

  await axios.get(`${linkValue}`, config).then((res: {status: number, data: any }) => {
    if (res.status === 200) {
      data = res.data;
    }
  });
  return data;
};

/*const authorizedHeader:AxiosRequestConfig = {
	headers: {
		"Content-Type": "application/text",
		Authorization: `Bearer ${getAccessToken()}`,
		Prefer: "allowthrottleablequeries",
	},
};*/


export interface SharepointConfig {
	siteGuuid: string;
	listGuuid: string;
	clientId: string;
	valoCategoryTaxonomyGroupId: string;
	valoCategoryTaxonomySetIds: string[];
}

export interface SharepointNewsListResponse {
	//id:string;
	"@odata.nextLink":string;
	value: SharepointNewsItem[];
}

export interface SharepointNewsItem {
	id: number;
	eTag: string
	fields: SharepointNewsField;
}

export interface SharepointNewsField {
	Title: string;
	Description:string;
	EncodedAbsUrl: string;
	BannerImageUrl: {
		Description: string;
		Url: string;
	};
	ValoNewsPublishDate: Date;
	ValoBusinessArea: ValoTag;
	ValoTags: ValoTag[];
	"_LikeCount": string;
	"_CommentCount": string;
}

export interface ValoTag {
	Label: string;
	TermGuid: string;
	WssId: string;
}

export interface ThumbnailResponse {
	id: string;
	b64Content: string;
	httpCode: number;
}

export interface ThumbnailRequest {
	id: string;
	url: string;
}

export interface PersonalNewsResponse {
	path?: string|undefined;
 	title: string;
	author: string;
	pictureThumbnailURL: string;
	description: string;
	listID: string;
	listItemID: string;
	valoOfficeLocation: string;
	firstPublishedDate: Date;
	spWebUrl: string;
	originalPath: string;
	valoTags: string[];
	highlight: boolean;
	thumbnail: string;
	uniqueId: string;
	siteId: string;
}

export interface GraphNewsContentResponse {
	value: Array<{
		title: string;
		webParts: GraphWebPart[];
	}>
}

export interface GraphWebPart {
	type: string;
	data: {
		innerHTML: string;
	}
}

export interface SharepointTermSetDescription {

}

export interface GraphValueResponse<T> {
	"@odata.context": string;
	value: Array<T>
}

export interface SharepointTerm {
	id: string;
	labels: SharepointTermLabel[];
}

export interface SharepointTermLabel {
	name: string;
	isDefault: string;
	language: string;
}

export interface SharepointTermSet {
	id: string;
	localizedNames: Array<{
		name: string;
		languageTag: string;
	}>
}


export interface ValoCategory {
	name: string;
}

export interface ValoCategoryGroup {
	name: string;
	categories: ValoCategory[];
}