import React, { Fragment } from "react";
import "../TeamMember/teamMember.scss";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamMember_Link1, TeamMember_Link2 } from "../../links";
import { NewTeamMembers_Title } from "../../strings ";
import {Card, Container, Row, Col} from "react-bootstrap";

import {
  GetTeamMemberDetails,
  GetTeamsConfig,
} from "../../services/serviceApi";
interface IProps {
  TeamMember: Array<any>;
}

interface IState {
  TeamMember?: Array<any>;
  DeepLinkBaseUrl?: string | null;
  payStubs?: string | null;
  benefits?: string | null;
  rewards?: string | null;
  kudos?: string | null;
  survey?: string | null;
  report?: string | null;
  shifts?: string | null;
  channelChatUrl?: string | null;
  portalAppId?: string | null;
  surveyAppId?: string | null;
  insightsAppId?: string | null;
  trainingAppId?: string | null;
}

class TeamMember extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      TeamMember: [],
      DeepLinkBaseUrl: null,
      payStubs: null,
      benefits: null,
      rewards: null,
      kudos: null,
      survey: null,
      report: null,
      shifts: null,

      channelChatUrl: null,
      portalAppId: null,
      surveyAppId: null,
      insightsAppId: null,
      trainingAppId: null,
    };
  }

  async componentDidMount() {
	await this.bindData();
 }

  async bindData() {
	let TeamMember: any[] = [];
	let TeamMemberValue: any[];
    //getting new team member details
    TeamMemberValue = await GetTeamMemberDetails().catch((err: any) => {
      console.log(err);
	});
    if (TeamMemberValue !== null && TeamMemberValue?.length !== 0) {
      TeamMember = TeamMemberValue.slice(0, 2);
    }
    let teamsConfigfromApi = await GetTeamsConfig().catch((err: any) => {
      console.log(err);
    });
    if (teamsConfigfromApi != null) {
      this.setState({
        TeamMember: TeamMember,
        DeepLinkBaseUrl: teamsConfigfromApi?.deepLinkBaseUrl,
        payStubs: teamsConfigfromApi?.payStubsAppId,
        benefits: teamsConfigfromApi?.benefitsAppId,
        rewards: teamsConfigfromApi?.rewardsAppId,
        kudos: teamsConfigfromApi?.kudosAppId,
        survey: teamsConfigfromApi?.surveyAppId,
        report: teamsConfigfromApi?.reportAppId,
        shifts: teamsConfigfromApi?.shiftsAppId,

        channelChatUrl: teamsConfigfromApi?.channelChatUrl,
        portalAppId: teamsConfigfromApi?.portalAppId,
        surveyAppId: teamsConfigfromApi?.surveyAppId,
        insightsAppId: teamsConfigfromApi?.insightsAppId,
        trainingAppId: teamsConfigfromApi?.trainingAppId,
      });
    } else {
	  console.log("No Team Config Found for TeamMembers!!");
    }
  }
  openTask = (selectedTask: string) => {
    microsoftTeams.initialize();
    microsoftTeams.executeDeepLink(selectedTask);
  };
  render() {
	const { TeamMember = [] } = this.state;
	console.log("These Teammember will be displayed")
	console.log(TeamMember);
    return (
      <Fragment>
        <div className="row-title">{NewTeamMembers_Title}</div>
		<Container>
			<Row xs={2} md={2}>
			{TeamMember.map(member => 
					<Col className="profile-card">
						<Card>
							<Card.Img variant="top" src={member.profilePhotoUrl}/>
							<Card.Body>
								<Card.Title as={"h4"}>
									{member.givenName}
								</Card.Title>
								<div
									id="newMemberChat0"
									className="btn hi pointer"
									onClick={() => this.openTask(`${TeamMember_Link1}`)}
								>
									Begrüßen
								</div>
							</Card.Body>
						</Card>
					</Col>
				) }
			</Row>

		</Container>
      </Fragment>
    );
  }
}

export default TeamMember;
