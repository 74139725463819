import React, { useContext, useEffect, useState } from "react"
import { GetGeneralNewsData, PersonalNewsResponse, SharepointConfig, ValoCategory, ValoCategoryGroup, GetValoCategories, GetFilteredNews } from "../../services/serviceApi";
import { Accordion, ListGroup, Card, Form, Button, useAccordionToggle, Navbar, FormControl} from "react-bootstrap";
import {ArrowLeft, Filter} from "react-bootstrap-icons"
import {NewsItemComponent} from "../Announcements/Announcements";
//import {SharepointContext} from "../Home/Home";
import { ApiNewsResponse } from "../../services/ApiClient";
import "./newsOverview.scss"
import { StateSetter } from "../../types";
import { useHistory } from "react-router-dom";

export const NewsOverview: React.FC<{categories: ValoCategoryGroup[]}> = ({categories}) => {
	const [filter, setFilter] = useState<CategoryFilterGroup[]>(() => mapCategoriesToFilter(categories));
    //const [pageNo, setPageNo] = useState<number>(0);
    const [news, setNews] = useState<ApiNewsResponse[]>([]);
	//const spConfig = useContext<Promise<SharepointConfig>>(SharepointContext);
	
	const [showFilter, setShowFilter] = useState<Boolean>(false);

	useEffect(() => {
		setFilter(mapCategoriesToFilter(categories))
	}, [categories, setFilter])

	useEffect(() => {
		const valoTags: string[] = [];
		filter.find(filter => filter.name.toUpperCase() === "TAGS")?.options.forEach(option => {
			if (option.active) {
				valoTags.push(option.name);
			}
		});

		const locations: string[] = [];
		filter.find(filter => filter.name === "Office Location")?.options.forEach(option => {
			if (option.active) {
				locations.push(option.name);
			}
		});
		
		getNews(setNews, valoTags, locations);
	}, [filter, setNews])

	return (
		<div id="NewsOverview">
			<ControlBar toggleFilter={() => setShowFilter(!showFilter)}/>
			<div className={"content"}>
				{ showFilter ?
					<FilterPane filter={filter} updateFilter={setFilter}/>
					: ""
				}
				<NewsList news={news} loadFurther={() => {}}/>
			</div>
				
		</div>
	)
}


const NewsList: React.FC<{news: ApiNewsResponse[], loadFurther: () => void}> = ({news, loadFurther}) => {
	const history = useHistory();
	const openTask = (pageId?: string, bannerUrl?: string, siteId?:string) => {
		if (pageId && bannerUrl) {
			history.push(`/news?pageId=${pageId}&bannerUrl=${encodeURIComponent(bannerUrl)}&siteId=${siteId}`)
		}
	  };
	
	return(
        <div className="newsList">
            <ListGroup>
                {news.map(content => 
                    <ListGroup.Item>
                        <NewsItemComponent  item={content} onClick={openTask}/>
                    </ListGroup.Item>
                )}
                <ListGroup.Item onClick={loadFurther}>
                    Load More...
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}

const FilterPane: React.FC<{filter: CategoryFilterGroup[], updateFilter: UpdateState<CategoryFilterGroup[]>}> = ({filter, updateFilter}) => {
	const [activeCategory, setActiveCategroy] = useState<string>("")
	
	const setFilterGroup = (updatedGroup: CategoryFilterGroup) => {
		updateFilter(list => UpdateArrayElement(list, (group) => group.name, updatedGroup))
	}



	return(
		<Accordion activeKey={activeCategory}>
			{filter.map((group, index) => {
				return	(
					<FilterGroupComponent group={group} key={group.name} changeGroupValues={setFilterGroup} setActive={setActiveCategroy} current={activeCategory}/>
					)
				})}
		</Accordion>
	)
}

const updateGroup = (changeGroupValues: (value: CategoryFilterGroup) => void, updatedElement: CategoryFilter, group: CategoryFilterGroup) => {
	const newOptions = UpdateArrayElement(group.options, (opt) => opt.name, updatedElement );
	changeGroupValues({
		name: group.name, 
		options: newOptions
	});
}

const FilterGroupComponent: React.FC<{group: CategoryFilterGroup, changeGroupValues: (value: CategoryFilterGroup) => void, setActive: StateSetter<string>, current: string}> = ({group, changeGroupValues, setActive, current}) => {

	return (
		<Card>
			<Card.Header>
				<Accordion.Toggle as={Button} eventKey={group.name} variant="link" onClick={() => setActive((current) => current === group.name ? "" : group.name)}>
					{group.name}
				</Accordion.Toggle>
			</Card.Header>
			<Accordion.Collapse eventKey="test" in={group.name === current}>
				<Card.Body>
					<Form>
						<Form.Group>
							{group.options.map(element => {
								return <FilterElement element={element} changeActive={(filter) => updateGroup(changeGroupValues, filter, group)} key={element.name}/>
							})}
						</Form.Group>
					</Form>
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	)
}

const FilterElement: React.FC<{element: CategoryFilter, changeActive: (value: CategoryFilter) => void}> = ({element, changeActive}) => {
	return (
		<Form.Check label={element.name} checked={element.active} onChange={(ev) => changeActive({...element, active: ev.currentTarget.checked})}/>
	)
}

type UpdateState<T> = React.Dispatch<React.SetStateAction<T>>;

const loadMoreNews = async (setNews: React.Dispatch<React.SetStateAction<number>>, spConf: Promise<SharepointConfig>) => {
    const conf = await spConf;
    GetGeneralNewsData(conf.listGuuid, conf.siteGuuid, 5, true)
}

interface CategoryFilter extends ValoCategory {
	active: boolean;
}

interface CategoryFilterGroup {
	name: string;
	options: CategoryFilter[];
}

const mapCategoriesToFilter = (categories: ValoCategoryGroup[]) : CategoryFilterGroup[] => {
	return categories.map<CategoryFilterGroup>(group => {
		return {
			name: group.name,
			options: group.categories.map<CategoryFilter>((cat) => {
				return {
					active: false,
					name: cat.name
				}
			})
		}
	})
}

const UpdateArrayElement = <C, I>(arr: C[], keySelector: (key: C) => I, updatedElement: C) => {
	return arr.map(old => {
		if (keySelector(old) === keySelector(updatedElement)) {
			return updatedElement;
		}
		return old;
	})
}
const ControlBar: React.FC<{toggleFilter: () => void}> = ({toggleFilter}) => {
    const history = useHistory();

    return(
        <Navbar className="bg-light justify-content-between" fixed="top">
            <Button variant="light" onClick={history.goBack}>
                <ArrowLeft />
            </Button>
			<Button variant="light"  onClick={toggleFilter}>
                <Filter />
            </Button>
            <Form inline>
                <FormControl type="text" placeholder="Search" className=" mr-sm-2" />
            </Form>
        </Navbar>
    )
}

const getNews = async (setNews: UpdateState<ApiNewsResponse[]>, valoTags: string[], locations: string[]): Promise<void> => {
	const news = await GetFilteredNews(valoTags, locations);
	setNews(news);
}