import React, { Fragment } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import "./announcements.css";
import { Announcements_TeamsLink } from "../../links";
import { Announcement_Title } from "../../strings ";
import { GetPersonalNews, PersonalNewsResponse } from "../../services/serviceApi";
import {ListGroup} from "react-bootstrap"
import { ApiNewsResponse } from "../../services/ApiClient";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";


type IProps = RouteComponentProps & {
  announcementData: Array<any>;
}

interface IState {
  data: PersonalNewsResponse[];
  renderedCard: any;
  list: PersonalNewsResponse[];
}

class Announcement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      renderedCard: "",
      list: [],
    };
  }
  async componentDidMount() {
    this.bindData();
  }

  openTask = (pageId?: string, bannerUrl?: string, siteId?:string) => {
	if (pageId && bannerUrl) {
		this.props.history?.push(`/news?pageId=${pageId}&bannerUrl=${encodeURIComponent(bannerUrl)}&siteId=${siteId}`)
	}
  };


  async bindData() {
	//getting announcemet adaptive card details
	try {
		let personalNews: PersonalNewsResponse[] = await GetPersonalNews();
		if (personalNews?.length !== 0) {
		  this.setState({
			list: personalNews,
			data: personalNews
		  });
		}
	}

	catch(error) {
		console.log("Error applying PersonalNews to State of PersonalNews Component!");
	}

  }

  handleClick() {
    microsoftTeams.initialize();
    microsoftTeams.executeDeepLink(`${Announcements_TeamsLink}`);
  }

  render() {
	const news = this.state.data;

    return (
      <Fragment>
        <div className="row-title">{Announcement_Title}</div>
		<ListGroup>
			{news.map(newsItem => 
				<ListGroup.Item>
					<NewsItemComponent item={newsItem} onClick={this.openTask}/>
				</ListGroup.Item>
			)}
		</ListGroup>
      </Fragment>
    );
  }
}

export const NewsItemComponent: React.FC<{item: ApiNewsResponse | PersonalNewsResponse, onClick?: (pageId?: string, bannerUrl?: string, siteId?:string) =>void | undefined}> = ({item, onClick}) => {
	const history = useHistory();
	
	const navigate = (pageId?: string, bannerUrl?: string, siteId?: string) => {
		if (onClick) {
			onClick(pageId, bannerUrl, siteId);
		}
	}

	
	return (
		<div className="news-item" onClick={() => navigate(item.uniqueId, item.pictureThumbnailURL, item.siteId)}>
			
			<img src={`data:image/jpeg;base64,${item.thumbnail}`} alt={item.title}/>
			<div className="news-item-text-container">
				<h3>{item.title}</h3>
				<p>{item.description}</p>
			</div>
		</div>
	)
}

export default withRouter(Announcement);
