import { set } from "lodash";
import React, { Fragment, FC } from "react";
import {Navbar, Form, FormControl, Button} from "react-bootstrap";
import { GetNewsContent, GetSharePointConfig, GetThumbnails, GraphWebPart, SharepointConfig } from "../../services/serviceApi";
import {useHistory, useLocation} from "react-router-dom"
import { News_Title } from "../../strings ";
import "./newsPage.css"
import {ArrowLeft} from "react-bootstrap-icons"



export const NewsPage: React.FC = () => {
    const query = useQuery();

    return (
        <NewsRoot bannerUrl={decodeURIComponent(query.get("bannerUrl") ?? "")} pageId={query.get("pageId")} siteId={query.get("siteId")}/>
    )
}

const NewsRoot: React.FC<{pageId: string|null, siteId: string|null, bannerUrl: string|null}> = ({pageId, siteId, bannerUrl}) => {
    const [title, setTitle] = React.useState<string>("");
    const [contents, setContents] = React.useState<GraphWebPart[]>([]);
    const [banner, setBanner] = React.useState<string>("");

    //Fetch News if on initial load and if page- or site-Id changes
    React.useEffect(() => {
        if (pageId && siteId) {
            fetchContent(pageId, siteId, setTitle, setContents);
        }

        if (bannerUrl) {
            fetchBanner(bannerUrl, setBanner)
        }

    }, [pageId, bannerUrl, siteId, setTitle, setContents, setBanner])

    return (
        <div id="newsPage">
            <ControlBar/>
            <div className="header">
                <NewsBanner b64Content={banner}/>
                <NewsTitle title={title}/>
            </div>

            <NewsContent contents={contents}/>
        </div>
    )
}

const ControlBar: React.FC = () => {
    const history = useHistory();

    return(
        <Navbar className="bg-light justify-content-between" fixed="top">
            <Button variant="light" onClick={history.goBack}>
                <ArrowLeft />
            </Button>
            <Form inline>
                <FormControl type="text" placeholder="Search" className=" mr-sm-2" />
            </Form>
        </Navbar>
    )
}

const NewsTitle: React.FC<{title: string}> = ({title}) => {
    return(
        <h1>{title}</h1>
    )
}

const NewsBanner: React.FC<{b64Content: string}> = ({b64Content}) => {
    return (
        <img className="d-block w-100" src={`data:image/jpeg;base64,${b64Content}`} alt="Loading..."/>
    )
}

const NewsContent: React.FC<{contents: GraphWebPart[]}> = ({contents}) => {
    return (
        <div className="newsContents">
            {contents?.map(content => {
                if (content?.type === "rte") {
                    return <NewsParagraph innerHtml={content.data.innerHTML}/>
                }
                return "";
            })}
        </div>
    )
}

const NewsParagraph: React.FC<{innerHtml: string}> = ({innerHtml}) => {
    return(
        <div className="newsContent" dangerouslySetInnerHTML={{__html:innerHtml}}>

        </div>
    )
}

const fetchContent = async(

    pageId: string, siteId: string, setTitle: React.Dispatch<React.SetStateAction<string>>, 
    setContents: React.Dispatch<React.SetStateAction<GraphWebPart[]>>) => {

        const content = await GetNewsContent(pageId, siteId);
        console.log("content:")
        console.log(content)
        if (content.value[0]) {
            setTitle(content.value[0].title);
            setContents(content.value[0].webParts)
        }
}

const fetchBanner = async (bannerUrl: string, setBanner: React.Dispatch<React.SetStateAction<string>>) => {
    var resp = await GetThumbnails([{id: "1", url: bannerUrl + "&resolution=3"}])
    setBanner(resp[0]?.b64Content)
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}